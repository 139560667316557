import React from "react";
import styled from "styled-components";
import Layout from "./src/components/Layout";
import LexiconList from "./src/components/lexicon/LexiconList";
import { SearchProvider } from "./src/components/search/SearchContext";
import { LexiconProvider } from "./src/components/lexicon/LexiconContext";
import { QueryParamProvider } from "use-query-params";
import greekUtils from "greek-utils";
import { navigate } from "gatsby";
import Definition from "./src/components/common/Definition";

const WordsListContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
`;

let wordsMap = {};

function generatePath(location) {
  return location.pathname + location.search;
}

const history = {
  push: (location) => {
    navigate(generatePath(location), { replace: false, state: location.state });
  },
  replace: (location) => {
    navigate(generatePath(location), { replace: true, state: location.state });
  },
};

export function wrapPageElement({ element, props }) {
  let childElement = element;

  if (!Object.keys(wordsMap).length && props.data?.words?.allEntries) {
    props.data.words.allEntries.forEach(({ name }) => {
      wordsMap[name] = greekUtils
        .sanitizeDiacritics(name)
        .replace("ῦ", "υ")
        .replace("ς", "σ");
    });
  }

  if (!props.custom404 && typeof element.key === "string") {
    if (element.key.startsWith("/lexicon")) {
      childElement = (
        <WordsListContainer>
          <LexiconList
            activeWord={props.pageContext.word}
            words={props.data.words.allEntries}
            wordsMap={wordsMap}
          />
          <Definition activeWord={props.pageContext.word?.definition} />
          {element}
        </WordsListContainer>
      );
    }
  }
  return (
    <QueryParamProvider history={history} location={props.location}>
      <SearchProvider>
        <LexiconProvider>
          <Layout {...props}>{childElement}</Layout>{" "}
        </LexiconProvider>
      </SearchProvider>
    </QueryParamProvider>
  );
}

import React from "react";
import styled, { css } from "styled-components";
import uam from "../assets/images/uam.png";
import nationalScienceCenter from "../assets/images/national-science-center.png";
const sharedStyle = css`
  height: auto;
  display: flex;
`;
const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  height: 50px;
  padding: 1rem 0;
  /* margin-top: 2rem; */
  border-top: 2px solid var(--grey);
`;

const Img = styled.img`
  align-self: center;
  max-height: 100%;
`;

const ImgContainer = styled.div`
  width: 66.66%;
  ${sharedStyle}
`;
const ImgContainerSmall = styled.div`
  width: 33.33%;
  justify-content: end;
  ${sharedStyle}
`;

export default function Footer() {
  return (
    <FooterContainer>
      <ImgContainer>
        <Img src={nationalScienceCenter} alt="National Science Center" />
      </ImgContainer>
      <ImgContainerSmall>
        <Img src={uam} alt="Adam Mickiewicz University Poznań" />
      </ImgContainerSmall>
    </FooterContainer>
  );
}

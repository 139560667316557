export const LAT_GREEK_MAP = {
  A: "Α",
  a: "α",
  B: "Β",
  b: "β",
  c: "Ξ",
  c: "ξ",
  D: "Δ",
  d: "δ",
  E: "Ε",
  e: "ε",
  Ê: "Η",
  ê: "η",
  é: "η",
  è: "η",
  F: "Φ",
  f: "φ",
  G: "Γ",
  g: "γ",
  H: "Η",
  h: "η",
  I: "Ι",
  i: "ι",
  j: "Σ",
  j: "ς",
  K: "Κ",
  k: "κ",
  L: "Λ",
  l: "λ",
  M: "Μ",
  m: "μ",
  N: "Ν",
  n: "ν",
  O: "Ο",
  o: "ο",
  Ô: "Ω",
  ô: "ω",
  P: "Π",
  p: "π",
  Q: "Θ",
  q: "θ",
  R: "Ρ",
  r: "ρ",
  S: "Σ",
  s: "σ",
  T: "Τ",
  t: "τ",
  U: "Υ",
  u: "υ",
  V: "Υ",
  v: "υ",
  w: "ω",
  W: "Ω",
  x: "χ",
  X: "Χ",
  y: "ψ",
  Y: "Ψ",
  Z: "Ζ",
  z: "ζ",
};

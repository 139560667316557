import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const NavStyles = styled.nav`
  border-bottom: 2px solid var(--grey);
  /* margin-bottom: 2rem; */

  padding: 2rem 0;
  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 2rem;
    align-items: center;
  }
  li {
    order: 1;
  }
  a {
    font-size: 2rem;
    text-decoration: none;
    display: block;
    &:hover {
      color: var(--blue);
    }
    @media (max-width: 800px) {
      font-size: 2rem;
    }
  }
  @media (max-width: 800px) {
    --columns: 2;

    ul {
      grid-template-rows: auto auto;
      grid-template-columns: repeat(var(--columns), 1fr);
      justify-items: center;
    }
    .logo-item {
      order: 0;
      grid-column: 1 / -1;
    }
    .logo {
      transform: none;
    }
  }
`;

export default function Nav({ location }) {
  const url = location.pathname ? location.pathname : "";

  return (
    <>
      <NavStyles>
        <ul>
          <li>
            <Link to="/" className={url === "/" ? "active" : ""}>
              PROJECT
            </Link>
          </li>
          <li>
            <Link
              to="/lexicon/ἀβάθ"
              className={url.startsWith("/lexicon/") ? "active" : ""}
            >
              LEXICON
            </Link>
          </li>
          {/* <li>
            <Link
              to="/simple-search"
              className={url.startsWith("/simple-search") ? "active" : ""}
            >
              SIMPLE SEARCH
            </Link>
          </li> */}
          <li>
            <Link
              to="/search-options"
              className={url.startsWith("/search-options") ? "active" : ""}
            >
              SEARCH OPTIONS
            </Link>
          </li>
          {/* <li>
            <Link
              to="/transcription"
              className={url.startsWith("/transcription") ? "active" : ""}
            >
              TRANSCRIPTION
            </Link>
          </li> */}
          <li>
            <Link
              to="/references"
              className={url.startsWith("/references") ? "active" : ""}
            >
              REFERENCES
            </Link>
          </li>
        </ul>
      </NavStyles>
    </>
  );
}

import React, { useState } from "react";

// Create a order context
const LexiconContext = React.createContext();

export function LexiconProvider({ children }) {
  // we need to stick state in here
  const [lexiconLexiconValue, setLexiconLexiconValue] = useState("");
  return (
    <LexiconContext.Provider
      value={[lexiconLexiconValue, setLexiconLexiconValue]}
    >
      {children}
    </LexiconContext.Provider>
  );
}

export default LexiconContext;

import { createGlobalStyle } from "styled-components";

const Typography = createGlobalStyle`
  html {
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  a {
    color: var(--black);
    /* text-decoration-color: var(--blue);  */
    /* Chrome renders this weird with this font, so we turn it off */
    text-decoration-skip-ink: none;
    &.active { 
      font-weight: bold;
      color: var(--blue);
    }
  }
  mark, .mark {
    background: var(--yellow);
    padding: 0 2px 2px 2px;
    margin: 0;
    display: inline;
    line-height: 1;
  }

  .center {
    text-align: center;
  }
  
  .justify {
    text-align: justify;
  }

  .long-text {
    line-height: 3rem
  }
  
  .tilt {
    transform: rotate(-2deg);
  }
  
  .definition {
    p {
      margin-block-start: 1em;
      margin-block-end: 1em;
    }
  }
`;

export default Typography;

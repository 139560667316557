import React from "react";
import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";
import GlobalStyles from "../styles/GlobalStyles";
import Typography from "../styles/Typography";

const SiteBorderStyles = styled.div`
  @media (min-width: 1100px) {
    max-width: 1000px;
    margin: 12rem auto 4rem auto;
    /* margin-top: clamp(2rem, 5vw, 12rem); */
    margin-top: 0;
    background-size: 1500px;
    padding: 0 5px;
    padding: 0 clamp(5px, 1vw, 25px);
    box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.044);
  }
`;

const ContentStyles = styled.div`
  background: var(--light-grey);
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  height: calc(90vh - 5px);
  @media (min-width: 1100px) {
    padding: 0 2rem;
  }
`;

const Heading = styled.h1`
  min-height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--blue);
  color: var(--dark-grey);
  transform: translateX(calc(clamp(5px, 1vw, 25px) * -1));

  width: calc(100% + 2 * clamp(5px, 1vw, 25px));
  font-size: 4rem;

  @media (max-width: 1100px) {
    font-size: 3rem;
    width: 100%;
    padding: 0 1rem;
  }
`;

const Content = styled.div`
  min-height: 0;
  margin-bottom: auto;
  flex-grow: 1;
  display: flex;
`;

export default function Layout({ children, location }) {
  return (
    <>
      <GlobalStyles />
      <Typography />
      <SiteBorderStyles>
        <Heading>LEXICON OF ORIENTAL WORDS IN ANCIENT GREEK</Heading>
        <ContentStyles>
          <Nav location={location} />
          <Content>{children}</Content>
          <Footer />
        </ContentStyles>
      </SiteBorderStyles>
    </>
  );
}

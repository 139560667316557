import React from "react";
import styled from "styled-components";

const DefinitionContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;

  padding-right: 3rem;
  margin-right: -3rem;

  scrollbar-width: thin;
  scrollbar-color: var(--black) var(--light-grey);

  em,
  i {
    font-style: italic;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey);
    border-radius: 6px;
    border: 3px solid var(--light-grey);
  }

  &::-webkit-scrollbar-track {
    background: var(--light-grey);
  }

  &::-webkit-scrollbar {
    width: 12px;
  }

  .entry-link {
    color: #0070c0;
  }

  > p {
    line-height: 1.5 !important;

    * {
      font-size: 2rem;
    }

    @media (max-width: 1100px) {
      line-height: 1.2 !important;
    }
  }

  > p:first-child {
    margin-top: 2rem !important;
  }

  > p.biblio {
    /* line-height: 1 !important;

    * {
      font-size: 1.5rem;
    } */
  }
`;

export default function Definition({
  activeWord,
  idleContent = "<p>Select word from the list</p>",
}) {
  return (
    <DefinitionContainer
      className="definition"
      dangerouslySetInnerHTML={{
        __html: activeWord || idleContent,
      }}
    ></DefinitionContainer>
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { transformKey } from "../../utils/alphabetTransform";
import LexiconContext from "./LexiconContext";
import { getWordsMap } from "../../utils/wordsMap";
import { SideBar } from "../search/SearchList.styled";

const List = styled.ul`
  list-style: none;
  overflow-y: auto;
  margin-bottom: -2rem;
  padding-right: 1rem;

  scrollbar-width: thin;
  scrollbar-color: var(--black) var(--light-grey);

  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey);
    border-radius: 6px;
    border: 3px solid var(--light-grey);
  }

  &::-webkit-scrollbar-track {
    background: var(--light-grey);
  }

  &::-webkit-scrollbar {
    width: 12px;
  }
`;

const Word = styled.li`
  margin-right: 3rem;
  margin-bottom: 5px;
  white-space: nowrap;
  list-style: none;
  font-size: 2rem;
  line-height: 1.5;

  a {
    text-decoration: none;
  }
`;

const Search = styled.input`
  width: calc(100% - 1rem);
`;

const SearchContainer = styled.div`
  margin-right: 2rem;
  margin-top: -2rem;
  padding: 2rem 0;
  border-bottom: 2px solid var(--grey);
`;

export default function WordsList({ activeWord, words }) {
  const scrollList = useRef(null);
  const [searchValue, setSearchValue] = useContext(LexiconContext);
  const wordsMap = getWordsMap(words);

  const [searchResults, setSearchResults] = useState([]);
  const handleChange = (event) => {
    setSearchValue(transformKey(event.nativeEvent));
  };
  useEffect(() => {
    const results = words.filter(({ name }) =>
      wordsMap[name].startsWith(searchValue)
    );
    setSearchResults(results);
  }, [searchValue]);

  useEffect(() => {
    setTimeout(() => {
      // scrollList.current.scroll(0, scroll);
    }, 0);
  }, []);

  return (
    <SideBar>
      <SearchContainer>
        <Search
          type="text"
          placeholder="Search"
          value={searchValue}
          onChange={handleChange}
        />
      </SearchContainer>
      <List ref={scrollList}>
        {searchResults.map((word) => (
          <Word key={word.id}>
            <Link
              to={`/lexicon/${word.name}`}
              className={word.name === activeWord?.name ? "active" : ""}
            >
              {word.name}
            </Link>
          </Word>
        ))}
      </List>
    </SideBar>
  );
}

import greekUtils from "greek-utils";

let wordsMap = {};

export const getWordsMap = (allEntries) => {
  if (!Object.keys(wordsMap).length) {
    allEntries.forEach(({ name }) => {
      wordsMap[name] = greekUtils
        .sanitizeDiacritics(name)
        .replace("ῦ", "υ")
        .replace("ς", "σ");
    });
  }

  return wordsMap;
};

import styled from "styled-components";

export const SideBar = styled.div`
  display: flex;
  flex-direction: column;
  overflow: visible;
  margin: 2rem 3rem 2rem 0;
  border-right: 2px solid var(--grey);
  height: calc(100% - 4rem);
  flex-grow: 1;
  min-width: 200px;
  max-width: 200px;

  @media (max-width: 1100px) {
    min-width: 110px;
  }
`;

export const List = styled.ul`
  list-style: none;
  overflow-y: auto;
  margin-bottom: -2rem;

  padding-right: 1rem;

  scrollbar-width: thin;
  scrollbar-color: var(--black) var(--light-grey);

  &::-webkit-scrollbar-thumb {
    background-color: var(--dark-grey);
    border-radius: 6px;
    border: 3px solid var(--light-grey);
  }

  &::-webkit-scrollbar-track {
    background: var(--light-grey);
  }

  &::-webkit-scrollbar {
    width: 12px;
  }
`;

export const Word = styled.li`
  margin-right: 3rem;
  margin-bottom: 5px;
  white-space: nowrap;
  list-style: none;
  font-size: 2rem;
  line-height: 1.5;
`;

export const SearchLink = styled.a`
  text-decoration: none;
`;

export const Search = styled.input`
  width: calc(100% - 1rem);
`;

export const SearchContainer = styled.div`
  margin-right: 2rem;
  margin-top: -2rem;
  padding: 2rem 0;
  border-bottom: 2px solid var(--grey);
`;

import React, { useMemo, useReducer, useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";

// Create a order context
const SearchContext = React.createContext();

export function SearchProvider({ children }) {
  const reducer = (state, action) => ({ ...state, ...action });
  const [periodState, setPeriodState] = useReducer(reducer, {});
  const [originState, setOriginState] = useReducer(reducer, {});

  // we need to stick state in here
  const [periodsParam, setPeriodsParam] = useQueryParam("period", StringParam);
  const [originsParam, setOriginsParam] = useQueryParam("origin", StringParam);

  const [activeWordParam, setActiveWordParam] = useQueryParam(
    "activeWord",
    StringParam
  );
  const [activeWord, setActiveWord] = useState("");

  const arePeriodsActive = useMemo(
    () => Object.values(periodState).includes(true),
    [periodState]
  );

  const areOriginsActive = useMemo(
    () => Object.values(originState).includes(true),
    [originState]
  );

  return (
    <SearchContext.Provider
      value={{
        activeWord,
        setActiveWord,
        activeWordParam,
        setActiveWordParam,
        periodState,
        setPeriodState,
        periodsParam,
        setPeriodsParam,
        originState,
        setOriginState,
        originsParam,
        setOriginsParam,
        arePeriodsActive,
        areOriginsActive,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export default SearchContext;

import { LAT_GREEK_MAP } from "./consts";

export function transformKey(e) {
  const keyCode = e.data;
  const value = LAT_GREEK_MAP[keyCode];

  if (value || keyCode?.length) {
    // insert a char at the cursor position
    if (e.target.selectionStart || e.target.selectionStart == "0") {
      // FF
      var startPos = e.target.selectionStart;
      var endPos = e.target.selectionEnd;
      e.target.focus();
      e.target.setSelectionRange(startPos, endPos);

      return transformToGreek(e.target.value);
    } else if (document.selection && document.selection.createRange) {
      // IE
      const sel = document.selection.createRange();
      sel.moveStart("character", -1); // select one char back
      sel.text = value; // replace this latin char by a greek char
    } else {
      // replace complete value, caret position is lost
      return transformToGreek(e.target.value);
    }
  }

  return e.target.value;
}

function transformToGreek(text) {
  let newText = text.toLowerCase().split("");
  const max = text.length;
  for (var i = 0; i < max; i++) {
    const c = LAT_GREEK_MAP[newText[i]];
    if (c) newText[i] = c;
  }
  return newText.join("");
}
